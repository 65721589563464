/* Donate.css */

/* Style the Donate container */
.Donate {
    padding-top: 100px;
}

/* Style the Donate container's inner content */
.Donate__container {
    max-width: 800px;
    /* Adjust the max width as needed */
    margin: 0 auto;
    /* Set the background color for the inner container */
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    /* Add a subtle box shadow */
}

/* Style the Donate heading */
.Donate__container__heading h1 {
    text-align: center;
        font-size: 36px;
        font-weight: bold;
        color: #FBD784 !important;
    
        margin-bottom: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: 6px;
        text-transform: uppercase;
        text-align: center;
        margin-bottom: 20px;
}

/* Style the image */
.Donate__container__content img {
    max-width: 80%;
    height: auto;
    display: block;
    margin: 0 auto;
    border: 2px solid #007bff;
    /* Add a border to the image */
    border-radius: 5px;
    /* Add a rounded border to the image */
}

/* Style the text content */
.Donate__container__content__text {
    font-size: 16px;
    line-height: 1.5;
    color: #666;
    /* Set the text color */
    margin-top: 20px;
}

/* Style links within the text content */
.Donate__container__content__text a {
    color: #007bff;
    /* Set link color */
    text-decoration: none;
}

/* Style links on hover */
.Donate__container__content__text a:hover {
    text-decoration: underline;
}