.radha {
    padding: 20px;
    margin-top: 50px;
}

.radha__header h1{
    font-size: 36px;
        font-weight: bold;
        color: #FBD784!important;

        margin-bottom: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: 6px;
        text-transform: uppercase;
        text-align: center;

}

.radha__header h1 {
    font-size: 28px;
    font-weight: bold;
    color: #333;
}

.radha__content {
    display: flex;
    justify-content: center;
}

.radha__content__left {
    max-width: 800px;
}

.radha__content__left__image img {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.radha__content__left__text {
    margin-top: 20px;
    line-height: 1.6;
    color: #333;
}

.radha__content__para {
    margin-bottom: 10px;
}

/* Add additional styles as needed */

/* Media Query for responsive layout */
@media (max-width: 768px) {
    .radha__content {
        flex-direction: column;
        align-items: center;
    }

    .radha__content__left {
        width: 100%;
    }

    .radha__header h1 {
        font-size: 24px;
    }
}