.vrindavan {
    padding: 20px;
}

.vrindavan__middle__3rd {
    text-align: center;
    margin-top: 50px;
}

.vrindavan__middle__3rd__top__heading {
    font-size: 28px;
        font-weight: 700;
        color: #fbd784;
        margin-bottom: 20px;
        font-weight: 700;
        line-height: normal;
        letter-spacing: 6px;
        text-transform: uppercase;
        text-align: center;
        margin-top: 100px;
}

.vrindavan__middle__3rd__top__para-1 {
    line-height: 1.6;
    color: #fff;
}

.pc-container {
    display: block;
    /* Show this container on PC view */
}

.mobile-container {
    display: none;
    /* Hide this container on mobile view */
}

.sponsor-container {
    margin-top: 30px;
}

.sponsor-card {
    border: 1px solid #ccc;
    border-radius: 8px;
    overflow: hidden;
}

.sponsor-card img {
    width: 100%;
    height: auto;
    display: block;
}

.container_vrindavan {
    max-width: 600px;
    margin: 0 auto;
}

/* Slick slider styles */
.slick-dots li button:before {
    font-size: 8px;
}

/* Add a media query for responsive layout */
@media (max-width: 768px) {
    .vrindavan__middle__3rd__top__heading {
        font-size: 24px;
    }

    .pc-container {
        display: none;
        /* Hide this container on PC view */
    }

    .mobile-container {
        display: block;
        /* Show this container on mobile view */
    }

    .slick-slider {
        width: 100%;
    }
}
.vrindavan__middle__3rd__top__img img{
    max-width: 100%;
        height: auto;
        border-radius: 8px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}