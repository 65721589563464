/* Styles for the top section of the About component */
.about__top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 50px;
    padding-top: 100px;
}
.vrindavan{
    padding-top: 100px!important;
    align-content: center;
    display: contents;
}


.about__top__left {
    flex: 1;
    padding-right: 50px;
}

.about__top__left__heading {
    font-size: 36px;
    font-weight: bold;
    color: #FBD784;
    margin-bottom: 20px;
    font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: 6px;
        text-transform: uppercase;
}

.about__top__left__para {
    color: white;
    font-size: 18px;
    line-height: 1.6;
}

.about__top__right {
    flex: 1;
}

.about_img1 {
    width: 100%;
    max-width: 400px;
    box-shadow: inset 10px 0 100px 10px #000000;
}
.about_img2{
    width: 100%;
        max-width: 400px;
        box-shadow: inset 10px 0 100px 10px #000000;
}
/* Media query for small screens */
@media screen and (max-width: 768px) {
    .about__top__left__heading{
        text-align: center;
    }
    .about__top {
        flex-direction: column;
        margin: 20px;
    }

    .about__top__left,
    .about__top__right {
        flex: 1;
        padding-right: 0;
    }

    .about_img1 {
        max-width: 100%;
        margin-top: 20px;
    }
    .about__middle__right__heading{
        text-align: center;
    }
}
.about{
    align-content: center;
}
/* Styles for the middle section of the About component */
.about__middle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 50px;
}

.about__middle__left {
    flex: 1;
}

.about_img2 {
    width: 100%;
    max-width: 400px;
}

.about__middle__right {
    flex: 1;
    padding-left: 50px;
}

.about__middle__right__heading {
    font-size: 36px;
    font-weight: bold;
    font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: 6px;
        text-transform: uppercase;
}

.about__middle__right__para {
    color: white;
    font-size: 18px;
    line-height: 1.6;
}
@media screen and (min-width: 768px) {
    .about__middle__mobile{
        display: none;
    }
}

/* Media query for small screens */
@media screen and (max-width: 768px) {
    .about__middle{
        display: none;
    }
    .about__middle__mobile {
        flex-direction: column;
        margin: 20px;
    }

    .about__middle__left,
    .about__middle__right {
        flex: 1;
        padding-left: 0;
    }

    .about_img2 {
        max-width: 100%;
        margin-bottom: 20px;
    }
         .about__middle {
             flex-direction: column;
         }
    
         .about__middle__left,
         .about__middle__right {
             width: 100%;
         }
         .about__middle__2nd__top__para-1{
            text-align: left;

         }
            .about__middle__2nd__top__para-2{
                text-align: left;
    
            }
}
/* Styles for the "about" div */
.about {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

/* Add width and max-width to control the size of the "about" div */
.about {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
}
.about__middle__right__heading{
    color: #FBD784;
}
/* CSS for about__middle__2nd div */
.about__middle__2nd {
    background-color: #000;
    padding: 60px 0;
}

.about__middle__2nd__top {
    max-width: 1200px;
    margin: 0 auto;
    text-align: center;
}

.about__middle__2nd__top__heading {
    font-size: 28px;
    font-weight: 700;
    color: #fbd784;
    margin-bottom: 20px;
    font-weight: 700;
        line-height: normal;
        letter-spacing: 6px;
        text-transform: uppercase;
}

.about__middle__2nd__top__img {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.25);
}

.about__middle__2nd__top__para-1,
.about__middle__2nd__top__para-2,
.about__middle__3rd__top__para-1 {
    font-size: 16px;
    color: #fff;
    line-height: 1.6;
    margin-bottom: 20px;
}

/* CSS for mobile view */
@media (max-width: 767px) {
    .about__middle__mobile {
        display: block;
    }

    .about__middle__left,
    .about__middle__right {
        margin-bottom: 30px;
    }
    .about__middle__2nd__top__img{
                margin-bottom: 20px;
    }
}
.about__middle__3rd__top__heading{
    font-size: 28px;
        font-weight: 700;
        color: #fbd784;
        margin-bottom: 20px;
        font-weight: 700;
        line-height: normal;
        letter-spacing: 6px;
        text-transform: uppercase;
        text-align: center;
        margin-top: 100px;
}

sponsor-card {
    transition: transform 0.2s ease;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.sponsor-card:hover {
    transform: scale(1.05);
}

.sponsor-container {
    max-width: 1200px;
}

.mobile-container {
    display: none;
    text-align: center;
}

/* Media query for mobile view */
@media (max-width: 767.98px) {
    .mobile-container {
        display: block;
       
    }

    .pc-container {
        display: none !important;
    }
    
}

.pc {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    /* Set a height to occupy the entire viewport height */
}
/* About.css (or your custom CSS file) */
/* Add this media query to adjust the slider image size for mobile devices */

/* Mobile devices (up to 576px) */
@media (max-width: 768px) {
    .about__middle__3rd {
        padding: 20px;
        /* Add some padding on smaller screens */
    }

    .about__middle__3rd__top__heading {
        font-size: 24px;
        /* Reduce font size on smaller screens */
    }

    .about__middle__3rd__top__para-1,
    .about__middle__3rd__top__para-2 {
        font-size: 14px;
        /* Reduce font size on smaller screens */
    }
}
/* About.css (or your custom CSS file) */

/* ... other CSS styles ... */

/* Style for the paragraphs inside about__middle__3rd__top */
.about__middle__3rd__top__para-1,
.about__middle__3rd__top__para-2 {
    font-size: 16px;
    line-height: 1.6;
    color: #666;
    /* Change the color as needed */
    word-wrap: break-word;
    /* Break long words to prevent overflow */
    overflow-wrap: break-word;
    /* Alternative property for better browser support */
}

/* ... other CSS styles ... */
/* CSS for the "about__middle__3rd" div */
.about__middle__3rd {
    background-color: #000;
    padding: 60px 0;
}

.about__middle__3rd__top {
    max-width: 1200px;
    margin: 0 auto;
    text-align: center;
}

.about__middle__3rd__top__heading {
    font-size: 28px;
    font-weight: bold;
    color: #fbd784;
    margin-bottom: 20px;
    text-transform: uppercase;
}

.about__middle__3rd__top__img {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.25);
    margin-bottom: 20px;
}

.about__middle__3rd__top__para-1,
.about__middle__3rd__top__para-2 {
    font-size: 16px;
    line-height: 1.6;
    color: #fff;
    margin-bottom: 20px;
}

/* CSS for mobile view */
@media (max-width: 767px) {
    .about__middle__3rd__top__heading {
        font-size: 24px;
    }

    .about__middle__3rd__top__para-1,
    .about__middle__3rd__top__para-2 {
        font-size: 14px;
    }
}