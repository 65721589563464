/* CSS for the "activity" div */
.activity {
    padding: 100px 0;
}

.activity h1 {
    font-size: 36px;
    font-weight: bold;
    color: #fbd784;
    margin-bottom: 30px;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 6px;
}

.activity__container {
    max-width: 1200px;
    margin: 0 auto;
}

.activity__container__top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 50px;
}

.activity__container__top__left__para {
    color: #fff;
    font-size: 18px;
    line-height: 1.6;
}

.activity__container__top__right img {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.25);
}

.activity__container__list__container__list__item ul {
    list-style-type: none;
    padding-left: 0;
    color: #fff;
    font-size: 18px;
    line-height: 1.6;
}

.activity__container__healthcare,
.activity__container__Brij,
.activity__container__fooder,
.activity__container__blanket,
.activity__container__distribution {
    padding: 60px 0;
}

.activity__container__healthcare .Tagline,
.activity__container__Brij .Tagline,
.activity__container__fooder .Tagline,
.activity__container__blanket .Tagline,
.activity__container__distribution .Tagline {
    position: relative;
    width: 288px;
    height: 25px;
}

.activity__container__healthcare .KingOfFort,
.activity__container__Brij .KingOfFort,
.activity__container__fooder .KingOfFort,
.activity__container__blanket .KingOfFort,
.activity__container__distribution .KingOfFort {
    position: absolute;
    left: 96px;
    top: 0;
    color: #fbd784;
    font-size: 18px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 6px;
    word-wrap: break-word;
}

.activity__container__healthcare .Line,
.activity__container__Brij .Line,
.activity__container__fooder .Line,
.activity__container__blanket .Line,
.activity__container__distribution .Line {
    position: absolute;
    width: 72px;
    height: 2px;
    left: 0;
    top: 10px;
    background: #fbd784;
}

.activity__container__healthcare p,
.activity__container__Brij p,
.activity__container__fooder p,
.activity__container__blanket p,
.activity__container__distribution p {
    color: #fff;
    font-size: 16px;
    line-height: 1.6;
}

.activity__container__list__container__list__item li {
    margin-bottom: 15px;
}

.activity__container__list__container__list__item li:last-child {
    margin-bottom: 0;
}

/* CSS for mobile view */
@media (max-width: 767px) {
   
    .activity h1 {
        font-size: 24px;
    }

    .activity__container__top {
        flex-direction: column;
    }

    .activity__container__top__left,
    .activity__container__top__right {
        width: 100%;
        margin-bottom: 30px;
    }

    .activity__container__top__left__para,
    .activity__container__list__container__list__item ul {
        font-size: 14px;
    }

    .activity__container__top__right img {
        max-width: 100%;
    }

    .activity__container__healthcare .Tagline,
    .activity__container__Brij .Tagline,
    .activity__container__fooder .Tagline,
    .activity__container__blanket .Tagline,
    .activity__container__distribution .Tagline {
        width: 100%;
        margin-bottom: 10px;
        text-align: center;
    }

    .activity__container__healthcare .KingOfFort,
    .activity__container__Brij .KingOfFort,
    .activity__container__fooder .KingOfFort,
    .activity__container__blanket .KingOfFort,
    .activity__container__distribution .KingOfFort {
        left: 0;
    }

    .activity__container__healthcare .Line,
    .activity__container__Brij .Line,
    .activity__container__fooder .Line,
    .activity__container__blanket .Line,
    .activity__container__distribution .Line {
        left: 50%;
        transform: translateX(-50%);
    }
    .KingOfFort{
        text-align: left;
    }
}
.activity__container__top__right img{
    height: 100%;
    width: 200vh !important;
}
.activity__container__list__container__list__item__list li{
   
    font-size: 2vh;
    color: #fbd784;
    text-align: center;
    font-weight: 700;
}