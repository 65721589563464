/* Styles for the "Offering" component */
.offering {
    text-align: center;
    padding: 50px 0;
}

.offering h1 {
    font-size: 36px;
    font-weight: bold;
    color: #FBD784;
    margin-bottom: 30px;
}

.offering__para {
    color: #666;
    font-size: 18px;
    line-height: 1.6;
    margin-bottom: 40px;
}

.offering__row {
    display: flex;
    justify-content: center;
}

.offering__col {
    flex: 0 0 33.3333%;
    max-width: 33.3333%;
}

.offering__card {
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    overflow: hidden;
    transition: transform 0.2s ease;
}

.offering__card:hover {
    transform: scale(1.05);
}

.offering__card__img img {
    width: 100%;
    max-height: 200px;
    object-fit: cover;
    border-radius: 8px 8px 0 0;
}

.offering__card__body {
    padding: 20px;
}

.offering__card__title {
    font-size: 20px;
    font-weight: bold;
    color: #333;
    margin-bottom: 10px;
}
.transparent-bg{
    background-color: transparent !important;
}
/* Media query for smaller screens */
@media screen and (max-width: 768px) {
    .offering__row {
        flex-wrap: wrap;
    }
    .row-1{
        margin-left: 0;
    }

    .offering__col {
        flex: 0 0 50%;
        max-width: 50%;
    }

    /* For even smaller screens, show cards in a single column */
    @media screen and (max-width: 576px) {
        .offering__col {
            flex: 0 0 100%;
            max-width: 100%;
        }
    }
    .offering__para{
        text-align: left;
    }
}