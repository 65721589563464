.mahaprabhu {
    padding-top: 70px;
}

.mahaprabhu__container {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.mahaprabhu__container__content__right img {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    /* Hide the image for mobile view */
}

.mahaprabhu__container__title h1{
    text-align: center;
    font-size: 36px;
        font-weight: bold;
        color: #FBD784 !important;
    
        margin-bottom: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: 6px;
        text-transform: uppercase;
        text-align: center;
    margin-bottom: 20px;
}

.mahaprabhu__container__title h1 {
    font-size: 28px;
    font-weight: bold;
    color: #333;
}

.mahaprabhu__container__content {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.mahaprabhu__container__content__left {
    max-width: 800px;
}

.mahaprabhu__container__content__left p {
    margin-bottom: 20px;
    line-height: 1.6;
    color: #333;
}

.mahaprabhu__container__content__right img {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Add additional styles as needed */

/* Media Query for responsive layout */
@media (max-width: 768px) {
    .mahaprabhu__container__content {
        flex-direction: column;
        align-items: center;
    }

    .mahaprabhu__container__content__left {
        width: 100%;
    }

    .mahaprabhu__container__title h1 {
        font-size: 24px;
    }
    .image-pc{
        display: none;
    }
}
@media (min-width: 768px) {
    .image-mobile{
        display: none;
    }
}