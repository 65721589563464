.niyamsewa_image{
    max-height: 712px;
    padding-left: 200px;
}
.niyamsewa_right{
    display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 27px;
}
.niyamsewa_container{
    padding-top: 1px;
        justify-content: center;
        align-items: center;
        gap: 232px;
        display: flex;
}