/* Contact.css */

.wrapper {
    position: fixed;
    bottom: 30px;
    right: 30px;
    z-index: 999;
    display: flex;
    flex-direction: column;
    /* Set the flex direction to column */
}

.social {
    display: none;
    /* Initially hide the social icons */
    width: 50px;
    height: 50px;
    background-color: #007bff;
    /* Change the background color as needed */
    color: #ffffff;
    /* Change the icon color as needed */
    border-radius: 50%;
    margin-bottom: 10px;
    /* Add margin at the bottom for spacing between icons */
    font-size: 24px;
    line-height: 50px;
    text-align: center;
    transition: transform 0.3s ease-in-out;
}

.show {
    display: inline-block;
    /* Show the social icons when class 'show' is applied */
}

.close-button {
    display: inline-block;
    width: 50px;
    height: 50px;
    background-color: #007bff;
    /* Change the background color as needed */
    color: #ffffff;
    /* Change the icon color as needed */
    border-radius: 50%;
    margin-bottom: 10px;
    /* Add margin at the bottom for spacing between icons */
    font-size: 24px;
    line-height: 50px;
    text-align: center;
    transition: transform 0.3s ease-in-out;
}

.close-button-rotate {
    transform: rotate(45deg);
}