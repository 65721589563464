.landing_div {
    
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 300px;
    padding-left: 20vh;
    /* Adjust padding as needed */
}

.Group17 {
        display: flex;
        align-items: center;
    }

.QueenOfTheDeccan {
    color: #ffc178;
    font-size: 1rem;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 2px;
    word-wrap: break-word;
    margin-left: 16px;
}

.Rectangle21 {
    width: 50px;
    height: 2px;
    background: #ffc178;
}

.WelcomeNiyamsewa {
    text-align: left;
    padding-top: 32px;
}

.WelcomeText {
    color: white;
    font-size: 4rem;
    font-weight: 700;
    text-transform: capitalize;
    line-height: 3rem;
    word-wrap: break-word;
}

.ToText {
    color: white;
    font-size: 3rem;
    font-weight: 400;
    line-height: 3rem;
    word-wrap: break-word;
}

.NiyamsewaText {
    color: white;
    font-size: 4rem;
    font-weight: 700;
    text-transform: capitalize;
    line-height: 3rem;
    word-wrap: break-word;
}
@media (max-width: 767.98px) {
    .landing_div {
        padding-top: 100px;
        padding-left: 0px;
    }
    .WelcomeText {
        font-size: 2rem;
        
    }
    .ToText {
        font-size: 1.5rem;
    }
    .NiyamsewaText {
        font-size: 2rem;
        text-align: center;
    }
    .QueenOfTheDeccan {
        font-size: 0.8rem;
    }
    .Rectangle21 {
        width: 30px;
    }
    .WelcomeNiyamsewa{
        text-align: center;
    }
}
.donation-mobile{
    background: transparent;
        backdrop-filter: blur(10px);
        /* This applies the blur effect for the glass morphism */
        transition: backdrop-filter 0.3s ease;
       
}
.donation-mobile btn{
    padding-left: 50px;
}
.button{
    padding-left: 2vh!important;
}