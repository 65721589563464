/* Gallery.css */
.sponsor-card {
    transition: transform 0.2s ease;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.sponsor-card:hover {
    transform: scale(1.05);
}

.sponsor-container {
    max-width: 1200px;
}

.mobile-container {
    display: none;
    text-align: center;
}

/* Media query for mobile view */
@media (max-width: 767.98px) {
    .mobile-container {
        display: block;
    }

    .pc-container {
        display: none !important;
    }
}
.pc {
    display: flex!important;
    justify-content: center !important;
    align-items: center !important;
    /* Set a height to occupy the entire viewport height */
}
.heading{
    color: #FFF;
}