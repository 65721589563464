.nav-link{
color: #FFF!important;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

}
.navbar-nav{
    flex-shrink: 0;
 
    
}
.Niyamsewa{
    color: var(--wei, #FFF);
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: 0.24px;
        text-transform: capitalize;
}
.navbar-toggler-icon {
    color: white!important;
}
.nav-link{
    padding-right: 3rem!important;

}
/* NavBar.css */
.navbar {
    background: transparent;
    backdrop-filter: blur(10px);
    /* This applies the blur effect for the glass morphism */
    transition: backdrop-filter 0.3s ease;
    /* Add a smooth transition for the effect */
}

.navbar.collapsed {
    backdrop-filter: none;
    /* Remove the blur effect when the navbar is collapsed */
}
@media (max-width: 991px) {
    .donation{
        display: none;
    }
  

}

.logo{
    width: 80px;
    height: 80px;
}