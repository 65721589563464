.donation-footer {
  border-radius: 8px;
    background: #FFA343;
    font-size: 17px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
}
.donation:hover {
  background: #FFA343;
}

.footer {
  padding: 20px;
  
}
.footer text{
  color: white;
}


.footer__container {
  max-width: 1200px;
  margin: 0 auto;
}

.footer__top {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.footer__top__left {
  font-size: 24px;
  font-weight: bold;
  color: #333;
}
.footer__middle__left__heading{
  color: #FFF;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.3px;
 
}
/* Footer.css */

.footer__middle__center {
  flex: 2;
}

.footer__middle__center__heading {
  font-size: 18px;
  font-weight: bold;
  color: white;
}

.footer__middle__center__text {
  font-size: 14px;
  color: white;
}

.footer__middle__center__text__bold {
  font-weight: bold;
}

.footer__middle__center__text__email {
  color: #007bff;
  text-decoration: none;
}

.footer__middle__center__text__email:hover {
  text-decoration: underline;
}

.footer__middle__right {
  flex: 3;
  display: flex;
}

.footer__middle__right__coloumn1,
.footer__middle__right__coloumn2 {
  flex: 1;
}

.footer__middle__right__heading {
  font-size: 18px;
  font-weight: bold;
  color: #FFD1A0;
}

.footer__middle__right__text {
  font-size: 14px;
  color: white;
}

/* You can add more custom styles for your Footer as needed */
/* Footer Bottom */
.footer__bottom {
  display: flex;
  justify-content: space-between;
  padding: 20px 0;
}

.footer__bottom__left,
.footer__bottom__right {
  font-size: 14px;
}

/* Footer Bottom - Social Links */
.footer__bottom__right__social-links a {
  color: #fff;
  font-size: 20px;
  margin-right: 30px;
}

/* Footer Bottom - Text Divider */
.footer__bottom__left__text__divider {
  color: #fff;
  padding: 0 10px;
}
a{
  text-decoration: none;
}
@media screen and (min-width: 576px) {

  .footer__middle__left,
  .footer__middle__center,
  .footer__middle__right {
    flex: 1 1 33.33%;
  }
}

/* Styles for the bottom section of the footer */
.footer__bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* Media query for small screens */
@media screen and (max-width: 768px) {
  .footer__top {
    flex-direction: column;
  }

  .footer__middle__left,
  .footer__middle__center,
  .footer__middle__right {
    flex: 1 1 100%;
    margin-bottom: 20px;
  }

  .footer__bottom {
    flex-direction: column;
    text-align: center;
  }
  .footer__top__right{
    margin-top: 10px;
  }
}